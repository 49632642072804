<template>
  <SubscriptionDetails :prop="setSubscriber"/>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted} from "vue";
import {useRoute} from "vue-router";
import {Actions} from "../../../store/enums/StoreEnums";
import SubscriptionDetails from '@/views/apps/customers/SubscriptionDetails.vue'

export default {
  name: "SubscribersOne",
  components: {
    SubscriptionDetails
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      store.dispatch(Actions.GET_SUBSCRIBERS_ONE, {_id: route.params.id})
    })

    const setSubscriber = computed(() => {
      return  store.getters.subscribersOne
    })
    return {
      setSubscriber
    }
  },

}
</script>

<style scoped>

</style>